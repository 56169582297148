<template>
  <h-table
    :options="options"
    :columns="columns"
    :data="data"
    :loading="loading"
    @search="search"
  >
  </h-table>
</template>
<script>
import moment from "moment";

export default {
  data() {
    return {
      // columns: [
      //   {
      //     title: '',
      //     key: "farmName",
      //   },
      //   {
      //     title: "施用地块",
      //     key: "sceneName",
      //   },
      //   {
      //     title: "施用量",
      //     key: "amount",
      //     render: (h, { row }) => {
      //       return <span>{row.amount}kg</span>;
      //     },
      //   },
      //   {
      //     title: "施用时间",
      //     key: "applyTime",
      //     render: (h, { row }) => {
      //       let str = row.applyTime ? row.applyTime.substring(0, 10) : "";
      //       return <span>{str}</span>;
      //     },
      //   },
      // ],
      data: [],
      loading: false,
      page: {
        pageNo: 1,
        pageSize: 12,
        total: 0,
      },
      formLoading: false,
      formModel: {
        farmId: "",
        sceneId: "",
        amount: "",
        applyTime: new Date(),
      },
      farms: [],
      blocks: [],

      subInfo: {
        subthis: null,
        subAttName: "",
      },

      searchData: {
        farmId: "",
        sceneId: "",
      },
      hash: "",
    };
  },
  computed: {
    options() {
      return {
        search: {
          model: this.searchData,
          rules: {
            farmId: {
              name: this.hash == "/wasteApplication" ? "消纳基地" : "施用基地",
              type: "s",
              data: this.farms.map((item) => ({
                value: item.id,
                label: item.name,
              })),
            },
            sceneId: {
              name: this.hash == "/wasteApplication" ? "片区" : "施用地块",
              type: "s",
              data: this.blocks.map((item) => ({
                value: item.id,
                label: item.name,
              })),
            },
          },
        },
        add: {
          text: "施用上报",
          submit: this.submit,
        },
        page: this.page,
        form: {
          loading: this.formLoading,
          model: this.formModel,
          rules: {
            farmId: {
              name: "施用基地",
              type: "s",
              required: true,
              data: this.getFarms,
            },
            sceneId: {
              name: "施用地块",
              type: "s",
              required: true,
              data: [],
              getControl: (subthis, attrName) => {
                this.subInfo.subthis = subthis;
                this.subInfo.subAttName = attrName;
              },
            },
            amount: {
              name: "施用量",
              type: "i",
              required: true,
              append: "kg",
            },
            applyTime: {
              name: "施用时间",
              required: true,
              type: "date",
            },
          },
        },
      };
    },
    columns() {
      if (this.hash == "/wasteApplication") {
        return [
          {
            title: "消纳基地",
            key: "farmName",
          },
          {
            title: "片区",
            key: "sceneName",
          },
          {
            title: "施用量",
            key: "amount",
            render: (h, { row }) => {
              return <span>{row.amount}kg</span>;
            },
          },
          {
            title: "施用时间",
            key: "applyTime",
            render: (h, { row }) => {
              let str = row.applyTime ? row.applyTime.substring(0, 10) : "";
              return <span>{str}</span>;
            },
          },
        ];
      } else {
        return [
          {
            title: "施用基地",
            key: "farmName",
          },
          {
            title: "施用地块",
            key: "sceneName",
          },
          {
            title: "施用量",
            key: "amount",
            render: (h, { row }) => {
              return <span>{row.amount}kg</span>;
            },
          },
          {
            title: "施用时间",
            key: "applyTime",
            render: (h, { row }) => {
              let str = row.applyTime ? row.applyTime.substring(0, 10) : "";
              return <span>{str}</span>;
            },
          },
        ];
      }
    },
  },
  watch: {
    "formModel.farmId": {
      handler(val) {
        this.formModel.sceneId = "";
        if (!this.subInfo.subthis || !this.subInfo.subAttName) return;
        if (!val) {
          this.subInfo.subthis[this.subInfo.subAttName].sceneId = [];
        } else {
          this.getBlock(val).then((list) => {
            this.subInfo.subthis[this.subInfo.subAttName].sceneId = list;
          });
        }
      },
    },
    "searchData.farmId": {
      handler(val) {
        this.searchData.sceneId = "";
        if (!val) {
          this.blocks = [];
        } else {
          this.getBlock(val);
        }
      },
    },
  },
  methods: {
    //   提交
    submit(callback) {
      this.formLoading = true;
      let params = {
        ...this.formModel,
        applyTime: moment(this.formModel.applyTime).format("YYYY-MM-DD"),
      };
      this.$post(this.$api.WASTE_APPLICATION.PLANT_ADD, params)
        .then((res) => {
          this.$Message.success("上报成功");
          callback();
          this.getList();
        })
        .catch((e) => {
          console.log(e);
          callback(false);
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
    // 获取基地列表
    getFarms() {
      return new Promise((resolve) => {
        this.$post(this.$api.BASE_MANAGE.LIST, {
          pageNo: 1,
          pageSize: 99999,
          siteType: "1",
        }).then((res) => {
          this.farms = res.list;
          let list = res.list.map((item) => ({
            value: item.id,
            label: item.name,
          }));
          resolve(list);
        });
      });
    },
    // 获取地块列表
    getBlock(farmId) {
      return new Promise((resolve) => {
        this.$post(this.$api.PRODUCTION_AREA.LIST, {
          pageNo: 1,
          pageSize: 9999,
          farmId,
          siteType: "1",
        }).then((res) => {
          this.blocks = res.list;
          let list = res.list.map((item) => ({
            value: item.id,
            label: item.name,
          }));
          resolve(list);
        });
      });
    },
    search() {
      this.page.pageNo = 1;
      this.getList();
    },
    // 查询列表
    getList() {
      this.loading = true;
      this.$post(this.$api.WASTE_APPLICATION.PLANT_LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.searchData,
      })
        .then((res) => {
          this.data = res.list;
          this.page.total = +res.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getHash() {
      this.hash = this.$route.path;
      console.log();
    },
  },
  mounted() {
    this.getList();
  },
  created() {
    this.getHash();
  },
};
</script>
<style lang='less' scoped>
@import url("./index.less");
</style>